<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header pb-3">
        <h1>Staff Support Tickets</h1>
        <span class="flex align-center flex-wrap">
          <router-link :to="{name: 'openTickets'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isOpen, 'btn__outlined': !isOpen }">Open</button>
          </router-link>
          <router-link :to="{name: 'progressTickets'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isProgress, 'btn__outlined': !isProgress }">In Progress</button>
          </router-link>
          <router-link :to="{name: 'closedTickets'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isClosed, 'btn__outlined': !isClosed }">Closed</button>
          </router-link>
<!--           <router-link :to="{name: 'orderWatchlist'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isOrderWatchlist, 'btn__outlined': !isOrderWatchlist }">Orders</button>
          </router-link>
          <router-link :to="{name: 'groupWatchlist'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isGroupWatchlist, 'btn__outlined': !isGroupWatchlist }">Groups</button>
          </router-link> -->
          <button class="btn btn__outlined btn__small" @click="goBack()"><i class="fas fa-arrow-left"></i></button>
        </span>
        
      </div>
      <router-view :key="$route.params.id" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'supportHome',
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    isOpen() {
      return this.$route.name == 'openTickets';
    },
    isProgress() {
      return this.$route.name == 'progressTickets';
    },
    isClosed() {
      return this.$route.name == 'closedTickets';
    },
  },
  created () {
    this.$store.dispatch("getSupportRequests")  
  },
  methods: {
    goBack() {
      router.go(-1)
    },
  }
}
</script>